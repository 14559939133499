export const environment = {
  production: true,
  appVersion: 'v1',
  USERDATA_KEY: 'auth_superaides_dev',
  isMockEnabled: false,
  apiUrl: 'https://api.superaides.apptechasia.app/aide/',
  orgDetails: {
    orgId: 1,
    superAideLabel: 'SuperAides',
    orgName: 'SuperAide',
    logo: 'assets/media/logos/superaid_logo.png',
    favIcon: './assets/media/logos/superaid_logo.png',
    termsNServiceUrl: 'https://superaides.com/termsnservice.html',
    contactUrl: 'https://superaides.com/termsnservice.html',
    privacyUrl: 'https://superaides.com/termsnservice.html'
  }
};
