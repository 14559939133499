import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthModel } from 'src/app/modules/auth/_models/auth.model';
import { environment } from 'src/environments/environment';
/**
 * To intercept the Http Request and add the auth Token
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor() { }

    private refreshTokenInProgress = false;
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
    private tokenRefreshedSource = new Subject();
    private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    /**
     * Method to intercept the reuqest and add token
     * @param request out going request
     * @param next call to next intercepter in Pipeline
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler) {
        request = this.addAuthenticationToken(request);
        request = this.addOrgId(request);
        return next.handle(request);

    }

    private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        // If we do not have a token yet then we should not set the header.
        let accessToken = this.getAccessToken();
        if (!accessToken) {
            return request;
        }
        // If you are calling an outside domain then do not add the token.
        if (!request.url.startsWith(environment.apiUrl)) {
            return request;
        }

        return request.clone({
            headers: request.headers.set('Authorization', `Bearer ${accessToken}`)
        });
    }

    private addOrgId(request: HttpRequest<any>): HttpRequest<any> {
        if (!environment.orgDetails.orgId) {
            return request;
        }

        return request.clone({
            headers: request.headers.set('x-org-id', `${environment.orgDetails.orgId}`)
        });
    }


    getAccessToken() {
        let auth = this.getAuthFromLocalStorage();
        return auth ? auth.token : null;
    }

    private getAuthFromLocalStorage(): AuthModel {
        try {
            const authData = JSON.parse(
                localStorage.getItem(this.authLocalStorageToken)
            );
            return authData;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    private refreshToken() {
        if (this.refreshTokenInProgress) {
            return new Observable(observer => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;
            // return this.auth.triggerRefreshToken()
            //     .pipe(tap(() => {
            //         this.refreshTokenInProgress = false;
            //         this.tokenRefreshedSource.next();
            //     }));
        }
    }
}
